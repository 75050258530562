html {
    font-size: 10px;
  }

  #root{

    max-width: 100%;
    overflow-x: hidden;
  }
  
  html,
  body {
    padding: 0;
    margin: 0;
  }
  
  html,
  body,
  #root,
  .App,
  .fullHeight {
    height: 100%;
  }

  
  
  *, ::before, ::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'DM Sans', 'Open Sans', sans-serif;    
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1;
  }
  
  h1 {
    display: block;
    width: 100%;
    padding: 0;
    font-size: 3.0rem;
    line-height: 3.2rem;
    color: #000000;
    text-align: center;
    margin: 5rem 0;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
  
  h2 {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 auto 2rem;
    font-size: 2.0rem;
    line-height: 2.7rem;
    color: #000000;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: #d3d3d3;
  }

  h3{
    letter-spacing: 0.2rem;
    padding: 0 1rem ;
  }

  h4 {
    font-weight: 400;
    margin: 0.5rem;
    font-size: 1.6rem;
    letter-spacing: 0.08rem;
    line-height: 2rem;


  }
  
  p {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 auto 2rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #000000b9;
    text-align: left;
    font-weight: 100;
    border-bottom: #d3d3d3;
  }


  .infoDesc, .returns, .returnProcess{
    ul{
      li{
          width: 100%;
          padding: 0;
          margin: 0;
          font-size: 1.5rem;
          line-height: 2.5rem;
          color: #000000b9;
          text-align: left;
          font-weight: 100;
          border-bottom: #d3d3d3;
        
      }
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
    
  }
  
  a, a:hover {
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .main {
    width: 100%;
    height: calc(100% - 6.5rem);
    max-width: 1450px;
    padding: 0 10px;
    margin: 0 auto;
  }
  
  .controlPanel {
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 100%;
    padding: 3rem 0 6rem 25.0rem;
    border-bottom: 1px solid #d3d3d3;
  
    .sidebar {
      position: absolute;
      top: 0; left: 0;
      width: 25.0rem;
      height: 100%;
      z-index: 1;
      border-right: 1px solid #d3d3d3;
    }
  
    .content {
      padding: 0 10px;
    }
  }
  
  .adminLayout {
    height: calc(100% - 6.5rem - 5.8rem - 3.6rem);
  }
  
  .dashboardLayout {
    height: calc(100% - 6.5rem - 5.8rem);
  }

  .cookies {
    a{

      text {
        color: #000;
        background-color: black;
      }
    }
  }
  
  @media only screen and (max-width: 980px) {
    html {
      font-size: 9px;
    }
  
    .controlPanel {
      padding: 0 10px;
  
      .sidebar {
        position: relative;
        width: 100%;
        border: none;
        margin-bottom: 2.5rem;
      }
    }
  }
  