@import 'animation';

.heroWrapper {
    display: inline-block;
    // padding: 0px MAX(1rem, 10%);
    width: 100%;
    // padding: 0 10% 3rem;

    

    .heroContent {
        display: flex;
        padding: 2rem 6%;
        // padding: 2rem MIN(6rem, 21%);
        justify-content: space-evenly;
        // max-width: 1450px;
    
        .heroCTA{
            display: flex;
            // flex-direction: column;
            // justify-content: center;
            justify-content: space-around
            

            h1{
                color: rgb(68, 68, 68);
                // font-size: 7rem;
                // font-size: 4.5vw;
                font-size: calc(.5 * (4vh + 4.5vw));
                line-height: calc(.5 * (4vh + 4.5vw));
                margin: calc(.2 * (2vh + 2.5vw)) 0;
                text-align: left;
                width:1px
            }
            
        }

        
        .heroImage {
            width: 40%;
            width: MIN(40rem, 40%);
            overflow: hidden;
            // overflow-x: hidden;
            // overflow-y: hidden;

            img{
                margin: 0;
                transform: scale(1.5) rotate(20deg) translate(20%, 0%);
                // transform: rotate(30deg);
            }
            
        }

        .LRLbutton{
            width: MIN(30rem, 60%);
            margin: calc(.5 * (2vh + 2.5vw)) 0;
            color: black;
            font-size: 2.2rem;
            font-size: calc(.5 * (2vh + 2.5vw));
            letter-spacing: .8rem;
            word-spacing: 1rem;
            background: rgba(248, 226, 104, 0.712);
            border-radius: 1.5rem;
            
        }
    }

    h4{
        padding: 2rem;
        color: black;
        // font-weight: 100;
        // font-size: 1.5vw;
    }

}


@media only screen and (max-width: 500px) {
  
    .heroWrapper .heroContent {

        flex-direction: column;
        padding: 0rem;

        .heroCTA{
            padding: 2rem 0 2rem 2rem;
            align-items: center;
            h1{
                padding: 2rem 0;
                // font-size: 2rem;
                // line-height: 3.5rem;
                line-height: 4.5rem;
                // text-align: center;
                // display:table-caption;
                width:1px
            }
            h3{
                display: none;
            }
        }
        
        .heroImage {
            width: 100%;
        }

        
        .LRLbutton{
            width: 100%;
            margin: 2rem 0 5rem;
        }

    }
    h4{
        padding: 2rem;
        text-align: center;
        // line-height: 1rem;
    }
  }



  @media only screen and (min-width: 1441px) {
    .heroWrapper{

        padding: 0 20%;
    }
  }