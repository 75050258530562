
.header {
    height: 6.5rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    padding: 0 10% 0;
    // display: flex;
  
    .wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-direction: column;
      height: 100%;
      max-width: 1450px;
      margin: 0 auto;

      .logoBrand{
        display: flex;
        align-items: center;
      
        .logo {
          // position: absolute;
          // top: 50%;
          // width: 21%; 
          // left: 39.5%; // calc( (100 - __30__) /2)
          // -webkit-transform: translateY(-50%);
          // -moz-transform: translateY(-50%);
          // transform: translateY(-50%);
    
          display: flex;
          
          // flex-direction: column;
          // align-items: center;
          // flex: 0 0 auto;
          width: 6rem;
          // height: 60px;


          img {
            
            // display: block;
            // width: 100%;
            // margin: 0;
            position: relative;
            display: block;
            width: 100%;
            margin: 0;
            padding: .5rem;
          }

          // p{
          //   font-size: 1rem;
          //   word-wrap: normal;
          //   margin: 0;
          // }

        }

       

      }

      .menuToHide {
        display: none;
        
      }
  
      nav {
        
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0 ;
  
        ul, ul li {
          padding: 0;
          margin: 0;
          height: 100%;
        }
  
        ul {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
  
          li {
            display: inline-block;
            // margin: 0 4rem;
            // list-style-type: none;
  
            a {
              font-size: 1.2rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: black;
              // color: red;
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
              letter-spacing: 2px;
            }
            span {
              font-size: 1.2rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: black;
              // color: red;
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
              letter-spacing: 2px;
            }
          }
        }
      }

      .brandName{
        // position: absolute;
        display: flex;
        margin-left: 3rem;
        border-left: 0.1rem solid;
        padding-left: 3rem;
        // flex: 1 1 auto;
        h1{
          
          margin: 0;
          font-size: 3.6rem;
          line-height: 3.6rem;
          font-weight: 700;
          color: red;
        }
      }
  
      .callToActions {
        display: flex;
        // flex: 0 0 auto;
        // position: absolute;
        // top: 50%; right: 10px;
        // -webkit-transform: translateY(-50%);
        // -moz-transform: translateY(-50%);
        // transform: translateY(-50%);


        .ctaBtn{
          // background: white;
          // text-decoration-color: black;
          // border: 0.1rem solid;
          background-color: rgb(235, 245, 255);
          
        }

  
        i {
          display: none;
        }
  
        ul, li {
          margin: 0;
          padding: 0;
        }
  
        li {
          display: inline-block;
          list-style-type: none;
          margin-right: 1.5rem;
  
          &:last-child {
            margin-right: 0;
          }
  
          a, span {
            font-size: 1.2rem;
            line-height: 6.5rem;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
          }

          p {
            font-size: 1.2rem;
            color: black;
          }

  
        }
  
      }


      // .logo, .callToActions{
        
      //   flex: 0 0 15rem;

      // }

      // .brandName{

      //   flex: 0 0 auto;
      // }
  
    }
  
  }
  

  .header1 {
    height: 8rem;
    // display: flex;
    // align-items: center;
  }
  
  .mobileMenu {
    display: none;
  }
  
  @media only screen and (max-width: 980px) {
  
    .header{
      padding-left: 1%;
    }

    .header2 {
      display: none;
    }

    .menuToHide {
      display: block;
    }

    .mobileMenu {
      display: block;
    }
  
    

    .header .wrap {
      .callToActions {
        i {
          display: block;
        }
  
        ul li {
  
          &.hideOnMobile {
            display: none;
          }
  
          a , span {
            // font-size: 0;
            // text-align: left;
            
              font-size: 1.8rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: rgb(0, 0, 0);
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
              text-align: left;
              width: 100%;
              // padding-left: 2rem;
  
            i {
              font-size: 2.8rem;
              padding-left: 0;
              padding-right: 1rem;
            }
          }
          
        }
        

        p {
          display: none;
        }
      }
  
      .mainMenu {
        display: none;
        position: absolute;
        height: auto;
        top: 100%;
        left: 0;
        z-index: 10;
        background-color: white;
        border-bottom: 1px solid lightgray;
  
        &.active {
          display: block;
        }
  
        ul li {
          width: 100%;
          margin: 0;
          border-top: 1px solid lightgray;
  
          a {
            display: block;
            width: 100%;
            text-align: left;
            padding: 0 10px;


            i{
              padding-right: 2rem;
              // padding-bottom: 10rem;
            }
          }
        }
      }
  
    }

    .header .wrap {
      .brandName{
        // margin-left: 28%;
        margin-left: 10%;
        // padding: 0;
        // width: 100%;
        // padding: 0 50%;
        text-wrap: nowrap;
        // border-left: 0;

        h1{
          display: flex;
          align-items: center;
        }
      }

      .logoBrand {

        
        display: flex;
        align-items: center;

        .logo {
          margin: 0 auto;
          // width: 20.0rem;
          // width: 55%;
          position: absolute;
          // top: 50%;
          // left: 0; // calc( (100 - __30__) /2)
          // left: 10px;
          right: 10px
          // top: 5rem;
          // -webkit-transform: translateY(-55%);
          // -moz-transform: translateY(-55%);
          // transform: translateY(-55%);
    
          img {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
          }
        
      }
    }
   }
  
}