.area{
    // background: #da9ab3;  
    // background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    width: 100%;
    // height: 80vh;
    z-index: -1;
   
}



.circles{
    position: absolute;
    // background: #f7abc8;  

    background: 
    linear-gradient(180deg, rgba(248, 184, 139,0) 20%, rgba(248, 184, 139,.1) 20%, rgba(248, 184, 139,.1) 40%, rgba(248, 184, 139,.2) 40%, rgba(248, 184, 139,.2) 60%, rgba(248, 184, 139,.4) 60%, rgba(248, 184, 139,.4) 80%, rgba(248, 184, 139,.5) 80%),
    linear-gradient(45deg, rgba(255, 63, 63, 0.7) 20%, rgba(250, 105, 105,.4) 20%, rgba(253, 86, 86, 0.5) 40%, rgba(250, 105, 105,.4) 40%, rgba(255, 85, 85, 0.4) 60%, rgba(250, 105, 105,.3) 60%, rgba(250, 105, 105,.2) 80%, rgba(250, 105, 105,.1) 80%),
    linear-gradient(-45deg, rgba(186, 237, 145,0) 20%, rgba(186, 237, 145,.1) 20%, rgba(186, 237, 145,.1) 40%, rgba(186, 237, 145,.2) 40%, rgba(186, 237, 145,.2) 60%, rgba(255, 172, 172, 0.4) 60%, rgba(255, 156, 156, 0.4) 80%, rgba(238, 132, 124, 0.6) 80%),
	linear-gradient(90deg, rgba(255, 244, 245,0) 20%, rgba(255, 244, 245,.3) 20%, rgba(255, 244, 245,.3) 40%, rgba(255, 244, 245,.5) 40%, rgba(255, 244, 245,.5) 60%, rgba(255, 244, 245,.7) 60%, rgba(255, 244, 245,.7) 80%, rgba(255, 244, 245, 0.8) 80%),
    linear-gradient(-90deg, rgba(242, 162, 232,0) 20%, rgba(242, 162, 232,.4) 20%, rgba(242, 162, 232,.4) 40%, rgba(242, 162, 232,.5) 40%, rgba(242, 162, 232,.5) 60%, rgba(242, 162, 232,.6) 60%, rgba(242, 162, 232,.6) 80%, rgba(242, 162, 232,.8) 80%),
    linear-gradient(180deg, rgba(254, 163, 170,0) 20%, rgba(254, 163, 170,.4) 20%, rgba(254, 163, 170,.4) 40%, rgba(254, 163, 170,.6) 40%, rgba(254, 163, 170,.6) 60%, rgba(254, 163, 170,.8) 60%, rgba(254, 163, 170,.8) 80%, rgba(254, 163, 170,.9) 80%)
    // linear-gradient(-90deg, rgba(254, 163, 170,0) 20%, rgba(254, 163, 170,.4) 20%, rgba(254, 163, 170,.1) 40%, rgba(254, 163, 170,.1) 40%, rgba(254, 163, 170,.2) 60%, rgba(254, 163, 170,.2) 60%, rgba(254, 163, 170,.2) 80%, rgba(254, 163, 170,.2) 80%)
    ;

    background-color: rgb(255, 121, 103);
    background-size: 100% 100%;
    min-height: 100%;

    margin: 0;
    // top: 0;
    left: 0;
    width: 100%;
    height: 86%;
    overflow: hidden;
    z-index: -1;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    // background: rgba(255, 255, 255, 0.2);
    background: rgba(255, 0, 119, 0.342);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}